import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import './App.css';
import Logo from './assets/icons/jeppasport_zwart.svg';
import StarGrey from './assets/icons/star.png';
import StarYellow from './assets/icons/star_lit.png';
import GoogleReview from './assets/icons/google_nl.png';
import { appURI } from './config';

const App = () => {
  const [answers, setAnswers] = useState([]);
  const [remarks, setRemarks] = useState(undefined);
  const [currentHover, setCurrentHover] = useState(undefined);
  const [sending, setSending] = useState(false);
  const [averageScore, setAverageScore] = useState(0);
  const [reviewComplete, setReviewComplete] = useState(false);
  const [jeppaEvent, setJeppaEvent] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const questions = [
    'Algemene score',
    'De keuze van activiteiten',
    'Het materiaal',
    'Installatie en opruim van het materiaal',
    'De begeleiding',
    'De planning',
    'De communicatie met Jeppa Sport',
  ];

  const isLit = (id, score) => {
    let lit = false;
    for (let a = 0; a < answers.length; a++) {
      if (answers[a].id === id) {
        if (answers[a].score > score) {
          lit = true;
        }
        break;
      }
    }
    if (currentHover) {
      if (currentHover.id === id && currentHover.score > score) {
        lit = true;
      }
    }
    return lit;
  };

  const setHovering = (id, score) => {
    setCurrentHover({ id, score });
  };

  const unsetHovering = () => {
    setCurrentHover(undefined);
  };

  const saveScore = (id, question, score) => {
    let found = false;
    let currentAnswers = [...answers];
    for (let a = 0; a < currentAnswers.length; a++) {
      if (currentAnswers[a].id === id) {
        found = true;
        currentAnswers[a].score = score;
        break;
      }
    }
    if (!found) {
      currentAnswers.push({
        id,
        question,
        score,
      });
      setAnswers(currentAnswers);
    }
  };

  const reviewStars = (id, question) => {
    const starCount = 5;
    const starArray = [...Array(starCount).keys()];
    return (
      <div>
        {starArray.map((s, i) => (
          <div
            alt={`star${s}`}
            className="star"
            style={{
              backgroundImage: `url(${isLit(id, i) ? StarYellow : StarGrey})`,
            }}
            key={i}
            name={`${id}_${s}`}
            onClick={() => saveScore(id, question, i + 1)}
            onMouseOver={() => setHovering(id, i + 1)}
            onMouseOut={unsetHovering}
          />
        ))}
      </div>
    );
  };

  const sendReview = async () => {
    if (answers.length !== questions.length) {
      return Swal.fire(
        'Oeps',
        'Niet alle onderdelen kregen minstens 1 ster.',
        'error'
      );
    }
    if (!jeppaEvent?.length) {
      return Swal.fire(
        'Oeps',
        'Gelieve te laten weten over welk kamp of event het gaat.',
        'error'
      );
    }
    setSending(true);
    let totalScore = 0;
    for (let a = 0; a < answers.length; a++) {
      totalScore += answers[a].score;
    }
    let avgScore = totalScore / answers.length;
    const url = `${appURI}anonymous/review/add`;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        answers,
        remarks,
        jeppaEvent,
        firstName,
        lastName,
      }),
    });
    setSending(false);
    setAverageScore(Math.floor(avgScore));
    setReviewComplete(true);
  };

  return (
    <div className="root">
      <img className="logo" src={Logo} alt="logo" width="482" height="64" />
      <h1>Jeppa Sport Review</h1>
      {reviewComplete ? (
        <div>
          <p>We danken u om onze diensten te beoordelen.</p>
          {averageScore >= 4 ? (
            <>
              <p>
                Het zou ons enorm deugd doen mocht u ons ook beoordelen op
                Google via onderstaande link.
                <br />
                Dit duurt ongeveer 1 minuut.
                <br />
                <br />
                Alvast bedankt!
              </p>
              <a href="https://g.page/r/CYVukIr4UuYeEBM/review">
                <img
                  src={GoogleReview}
                  width={140}
                  height={140}
                  alt="review google"
                />
              </a>
            </>
          ) : null}
        </div>
      ) : (
        <>
          <p>We streven bij Jeppa Sport naar een 5-sterren service.</p>
          <p>
            Om onze diensten steeds te kunnen verbeteren willen we u vragen wat
            u van onze diensten vond.
            <br />
            Geef steeds een score van 1 tot 5 op elk van deze onderdelen
          </p>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: 480,
              }}
            >
              <label>Over welk kamp of event gaat deze review</label>
              <input
                type="text"
                value={jeppaEvent || ''}
                required
                onChange={(e) => setJeppaEvent(e.currentTarget.value)}
                style={{
                  marginBottom: 16,
                  height: 30,
                  fontSize: 16,
                  border: '1px solid grey',
                  marginTop: 6,
                  textAlign: 'center',
                }}
              />
              <label>Jouw voornaam</label>
              <input
                type="text"
                value={firstName || ''}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                style={{
                  marginBottom: 16,
                  height: 30,
                  fontSize: 16,
                  border: '1px solid grey',
                  marginTop: 6,
                  textAlign: 'center',
                }}
              />
              <label>Jouw naam</label>
              <input
                type="text"
                value={lastName || ''}
                onChange={(e) => setLastName(e.currentTarget.value)}
                style={{
                  marginBottom: 16,
                  height: 30,
                  fontSize: 16,
                  border: '1px solid grey',
                  marginTop: 6,
                  textAlign: 'center',
                }}
              />
              {questions.map((q, i) => (
                <div key={i}>
                  <p className="question">{q}</p>
                  <div className="stars">{reviewStars(i, q)}</div>
                </div>
              ))}
            </div>
            <p>
              Zijn er nog bijkomende opmerkingen?
              <br /> Heeft u een testimonial die u wilt achterlaten?
              <br /> We horen het graag van u.
            </p>
            <textarea
              rows={8}
              value={remarks || ''}
              id="remarks"
              name="remarks"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
          {sending ? (
            <ScaleLoader size={45} color={'#000000'} loading={true} />
          ) : (
            <button className="submit" onClick={() => sendReview()}>
              Bevestig review
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default App;
